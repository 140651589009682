<template>
    <div class="commonAgreement_contaier">
        <div class="agreement_main"  v-html="agreementHTML"></div>
        <div class="agreement_sign_info">
            <div class="agreemtn_sign_them">
                <div class="agreement_sign_name"><span>甲方：</span>{{data.agreement_company_name}}</div>
                <div class="agreement_sign_date">{{$tools.getDate(data.meet_sign_time,"yyyy年MM月dd日")}}</div>
            </div>
            <div class="agreemtn_sign_owner">
                <div class="agreement_sign_name">
                    <span>乙方：</span> 
                    <customButton  @click="doSignName(0)" v-if="!(data.meet_sign_name || signData[0]) ">
                        <div>
                            <span>签名</span>
                        </div>
                    </customButton>
                    <img :src="data.meet_sign_name?.split(',')[0] || this.signData[0]" @click="doSignName(0)" v-else alt="">
                </div>
                <div class="agreement_sign_date">{{$tools.getDate(data.meet_sign_time,"yyyy年MM月dd日")}}</div>
            </div>
        </div>
    </div>
</template>
<script>
import customButton from "@/components/unit/customButton"
import nzh from "nzh"
export default {
    name:"commonAgreement",
    props:{
        agreementData:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        agreementFields:{
            type:Array,
            default:()=>{
                return []
            }
        },
        signData:{
            type:Array,
            default:()=>{
                return []
            }
        }
    },
    data(){
        return {
            showSignName:false,
            agreementHTML:'',
            data:{},
            config:{
                nullText:/<(div|p)\s*class=[\s\w\"]*><\/(div|p)>/g,
            },
        }
    },
    mounted(){
        this.initData(this.agreementData,this.agreementFields)
    },
    components: {
       customButton,
    },
    methods: {
        initData(data,agreementFields){
            this.data = data;
            let html = this.data.project_agreement?.replace(this.config.nullText,"") || ""
            html = html.replace(/{{<\/*[\w]+>/,"{{") //{{<span>current_day}} ==>{{current_day}}
            html = html.replace(/<\/*[\w]+>}}/,"}}") //{{current_day</span>}} ==>{{current_day}}
            for(let item of agreementFields){
                let reg = new RegExp(`{{${item.field}}}`,"g")
                let val = this.data[item.field]
                let encodeS = nzh.cn.encodeS;
                if(item.use_format){
                    try{
                        val= eval(`${item.use_format}()`)
                    }catch(err){
                        console.log(err)
                    }
                }
                html  = html.replace(reg,val)
            }
            this.agreementHTML = html;
            this.$emit("initScroll")
        },
        verifyData(){
            if(!this.signData[0]){
                this.$tips.error({text:"请先签名"})
                return false;
            }
            return true;
        },
        doSignName(index){
            this.$emit("doSignName",index)
        },
    }
}
</script>
<style>
.commonAgreement_contaier{
    width: 100%;
    height: 100%;
}
.agreement_main{
    height:100%;
    overflow:hidden;
}
.agreement_main div.agreement_underline{
    border-bottom:1px solid #000;
} 
.agreement_main h1{
    width:100%;
    text-align:center;
    font-size:6vw;
} 
.agreement_main h3{
    width:100%;
    font-size:4vw;
    margin:3vw 0;
} 
.agreement_main b{
    font-weight: 500;
} 
.agreement_main p{ 
    display: block;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}
.agreement_main p,.agreement_main div{
    line-height:6.8vw;
    width:100%;
    /* text-indent: 8vw;; */
}
.agreement_sign_info{
    display: flex;
}
.agreement_sign_info>div{
    flex:1 0 0;
    margin-top:6vw;
}
.agreement_sign_name{
    height:16vw;
    display: flex;
    align-items: center;
}
.agreement_sign_name span{
    white-space: nowrap;
}
.agreement_sign_name img{
    width:24vw;
    height:auto;
}
</style>